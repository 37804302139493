import { apiBasic } from "./base";

export function getBillingByNumber(billing_number) {
  let api = apiBasic;
  return api.get(`v1/billings/${billing_number}/info`);
}

export function getBillingByNumberAndToken(billing_number, token) {
  let api = apiBasic;
  let params = {
    token
  }
  return api.get(`v2/billings/${billing_number}/info`, { params });
}

export function postVARequest(id, data) {
  let api = apiBasic;
  return api.post(`v1/billings/${id}/payments/va`, data);
}

export function postAlfamart(id) {
  let api = apiBasic;
  return api.post(`v1/billings/${id}/payments/alfamart`);
}

export function postIndomaret(id) {
  let api = apiBasic;
  return api.post(`v1/billings/${id}/payments/indomaret`);
}

export function postQris(id) {
  let api = apiBasic;
  return api.post(`v1/billings/${id}/payments/qris`);
}

export function generatePaymentWithToken(id, method, token, data = {}) {
  let api = apiBasic;
  let params = {
    token
  }
  return api.post(`v2/billings/${id}/payments/${method}`, data, { params });
}

export function generateAlfamartWithToken(id, token) {
  let api = apiBasic;
  let params = {
    token
  }

  return api.post(`v3/billings/${id}/payments/alfamart`, {}, { params });
}