import { useState, useRef, useEffect } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { useParams } from 'react-router-dom';

import viberlink_logo from '../assets/images/viberlink-white.png';
import { useInternetPackageHooks } from '../utils/InternetPackageHooks';
import useRegionHooks from '../utils/RegionHooks';
import { registerCostumer } from '../api/base';
import { useLocation } from 'react-router';
import { convertToRupiah } from '../tools/numerictools';

function RegisterPage() {
  let { slug } = useParams();

  const [ internetPackage, errorInternetPackageMsg, fetchInternetPackage ] = useInternetPackageHooks();
  let [ promoQuery, setPromoQuery ] = useState('');
  let [ packageQuery, setPackageQuery ] = useState('');

  let location = useLocation();
  
  useEffect(() => {
    let query = new URLSearchParams(location.search);
    if (query.get("package")) {
      handleChange("internet_package_id", query.get("package"));
      setPackageQuery(query.get("package"));
    }
  }, [])

  useEffect(() => {
    fetchInternetPackage({project: slug});
  }, [slug])

  const [ userData, setUserData ] = useState({
    name: '',
    email: '',
    gender: '',
    phone_number: '',
    id_number: '',
    id_number_type: 'id_card',
    customer_number: '',
    npwp_number: '',
    address_id: '',
    internet_package_id: '',
    registration_type: 'INSTALLATION_REQUEST',
    birthdate: '',
    birthdate_place: '',
    building_ownership_status: ''
  });  

  const userRegion = useRegionHooks();
  const installRegion = useRegionHooks();
  const [ sameAddress, setSameAddress ] = useState(false);
  const [ agreeTnc, setAgreeTnc ] = useState(false);
  const [ collapseTnc, setCollapseTnc ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const [alert, setAlert] = useState({show: false, message: '', type: ''});

  function handleChange(name, value){
    setUserData({ ...userData, [name]: value})
  }
  
  async function registerData(form) {
    setLoading(true);
    try {
      let params = {
        promo: promoQuery
      }
      let { data }  = await registerCostumer(form, params);
      setAlert({show: true, message: "Berhasil: " + data.message, type: 'success'})
      resetForm()
    } catch (error) {
      let message = "Sesuatu kesalahan sedang terjadi"

      if(error.response) {
        message = error.response.data.message
      }
      setAlert({show: true, message: message, type: 'danger'});
    }
    scrollToForm();
    setLoading(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    let form = { 
      ...userData, 
      address: userRegion.address, 
      address_id: userRegion.addressId,
      rt: userRegion.rt,
      rw: userRegion.rw,
      installation_address: {}
    }

    if (userData.registration_type === "INSTALLATION_REQUEST") {
      delete form.customer_number;
    }

    if (sameAddress) {
      form.installation_address = {
        address: userRegion.address,
        rt: userRegion.rt,
        rw: userRegion.rw,
        address_id: userRegion.addressId,
      }
    } else {
      form.installation_address = {
        address: installRegion.address, 
        rt: installRegion.rt,
        rw: installRegion.rw,
        address_id: installRegion.addressId,
      }
    }

    registerData(form)
  }

  const registerFormRef = useRef(null)
  const scrollToForm = () => registerFormRef.current.scrollIntoView({behavior: 'smooth'})   

  function resetForm() {
    // reset user data 
    setUserData({
      name: '',
      email: '',
      gender: '',
      phone_number: '',
      id_number: '',
      id_number_type: 'id_card',
      customer_number: '',
      npwp_number: '',
      address_id: '',
      internet_package_id: '',
      registration_type: 'INSTALLATION_REQUEST',
      birthdate: '',
      birthdate_place: '',
      building_ownership_status: ''
    })
    // reset user region
    userRegion.resetRegion()
    // reset install region
    installRegion.resetRegion()
  }

  return (
    <div className="page-content">
      <div className="form-v4-content">
        <div className="form-left">
          <img src={viberlink_logo} alt="viberlink logo" className="img-fluid mb-4" />
          {userData.internet_package_id && 
            <div className="text-2">
              <b>Paket yang dipilih:</b>
              {internetPackage.map((internetPack, index) => {
                if (userData.internet_package_id === internetPack.id) {
                  return (
                    <div className="text-2 box-package" key={index}>
                      <span>{internetPack.name}</span> <br />
                      {(promoQuery && internetPack.is_promo)
                        ? <>
                            <span className='origin-price d-block'>Rp.{convertToRupiah(internetPack.price)}</span>
                            <span className='price'>Rp.{convertToRupiah(internetPack.promo.discount_amount)}</span>
                          </> 
                        : <span className='price'>Rp.{convertToRupiah(internetPack.price)}</span>
                      }
                      

                      <br />
                      <p className='my-3'>
                        <ul className='pl-0' type="none">
                          <li>- {internetPack.description}</li>
                          <li>- Tipe {internetPack.internet_package_type.name}</li>
                          <li>- Kecepatan {internetPack.internet_package_varian.name}</li>
                        </ul>
                      </p>
                    </div>
                  )
                }
                return <div key={index} />
              })}
            </div>
          }
          

          <p className="text-1" id="tnc">
            <a role="button" style={{fontWeight: 'bold', display: 'block'}} onClick={() => setCollapseTnc(!collapseTnc)}><i className={`fa ${collapseTnc ?  'fa-caret-right' : 'fa-caret-down'}`}></i> Syarat dan Ketentuan</a>
          </p>
          <ol className={`pl-3 mt-3 ${!collapseTnc ? 'd-block' : 'd-none'}`}>
            <li>Biaya pemasangan dan biaya berlangganan paket pada bulan pertama (sesuai paket yang dipilih) akan ditagihkan sebelum awal pemasangan jaringan.</li>
            <li>Pembayaran langganan Viberlink akan ditagihkan di awal masa berlangganan dan tagihan akan dikiriman melalui email yang terdaftar.</li>
            <li>Pembayaran tagihan dilakukan secara transfer ke rekening perusahaan.</li>
            <li>Harga langganan sudah termasuk pajak 10%.</li>
            <li>Kecepatan layanan internet pada paket yang ditawarkan adalah kecepatan maksimum yang dapat dicapai.</li>
          </ol>

          {/* <div className="form-left-last">
            <Link to="cek-registrasi" className="account btn" style={{padding: "15px"}} >
              Cek Status Pendaftaran
            </Link>
          </div> */}
        </div>
        <form className="form-detail" onSubmit={event => handleSubmit(event)} id="register-form" ref={registerFormRef}>
          {alert.show &&
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              <ul className="pl-3 mb-0">
                {alert.message.split(',').map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
              <button type="button" className="close"  onClick={() => setAlert({show: false, message: '', type: ''})} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          }
          <h2>Paket</h2>
          <div className= "form-row">
            <label htmlFor="your_email">Pilihan Paket  <span className="text-danger">*</span></label>
            <select className="form-control input" required value={userData.internet_package_id} onChange={event => handleChange("internet_package_id", event.target.value)} disabled={packageQuery}>
              <option value="" disabled> -- Pilih Paket --</option>
              {internetPackage.map((pack, index) => (
                <option key={index} value={pack.id}>{pack.name}</option>
              ))}
            </select>
          </div>
          <h2>Data Pelanggan</h2>
          <div className= "form-row">
            <label htmlFor="registration_type">Jenis Pelanggan <span className="text-danger">*</span></label>
            <select className="input form-control" id="registration_type" value={userData.registration_type} onChange={event => handleChange("registration_type", event.target.value)}>
              <option value="">-- Jenis Pelanggan --</option>
              <option value="INSTALLATION_REQUEST">Pelanggan Baru</option>
              <option value="INSTALLED">Pelanggan Lama</option>
            </select>
          </div>
          {userData.registration_type === "INSTALLED" &&
            <div className= "form-row">
              <label htmlFor="id_number">Nomor Pelanggan <span className="text-danger">*</span></label>
              <input type="text" name="id_number" id="id_number" className="input-text" required value={userData.customer_number} onChange={event => handleChange("customer_number", event.target.value)} /> 
            </div>
          }
          <div className= "form-row">
            <label htmlFor="name">Nama Lengkap <span className="text-danger">*</span></label>
            <input type="text" name="name" id="name" className="input-text" required value={userData.name} onChange={event => handleChange("name", event.target.value)} /> 
          </div>
          <div className= "form-row">
            <label htmlFor="your_email">Nomor Identitas <span className="text-danger">*</span></label>
            <div className="form-row row">
              <div className="col"><label className='custom-radio'><input type="radio" name='type_identity' checked={userData.id_number_type === "id_card"} onChange={() => handleChange("id_number_type", "id_card")} /> KTP</label></div>
              <div className="col"><label className='custom-radio'><input type="radio" name='type_identity' checked={userData.id_number_type === "passport"} onChange={() => handleChange("id_number_type", "passport")} /> Passpor</label></div>
            </div>
            <input type="text" name="id_number" id="id_number" className="input-text" required value={userData.id_number} onChange={event => handleChange("id_number", event.target.value)} /> 
          </div>
          <div className= "form-row">
            <label htmlFor="npwp_number">Nomor NPWP</label>
            <input type="text" name="npwp_number" id="npwp_number" className="input-text" value={userData.npwp_number} onChange={event => handleChange("npwp_number", event.target.value)} /> 
          </div>
          <div className= "form-row">
            <label htmlFor="email">Email <span className="text-danger">*</span></label>
            <input type="email" name="email" id="email" className="input-text" required value={userData.email} onChange={event => handleChange("email", event.target.value)} /> 
          </div>
          <div className= "form-row">
            <label htmlFor="birthdate_place">Tempat Lahir <span className="text-danger">*</span></label>
            <input type="text" name="birthdate_place" id="birthdate_place" className="input-text" required value={userData.birthdate_place} onChange={event => handleChange("birthdate_place", event.target.value)} /> 
          </div>
          <div className= "form-row">
            <label htmlFor="birthdate">Tanggal Lahir <span className="text-danger">*</span></label>
            <input type="date" name="birthdate" id="birthdate" className="input-text" value={userData.birthdate} onChange={event => handleChange("birthdate", event.target.value)}  required />
          </div>
          <div className= "form-row">
            <label htmlFor="gender">Jenis Kelamin <span className="text-danger">*</span></label>
            <select className="input form-control"  name="gender" id="gender" value={userData.gender} onChange={event => handleChange("gender", event.target.value)}>
              <option value="">-- Pilih Jenis Kelamin --</option>
              <option value="L">Laki-laki</option>
              <option value="P">Perempuan</option>
            </select>
          </div>
          <div className= "form-row">
            <label htmlFor="phone_number">Nomor Telepon <span className="text-danger">*</span></label>
            <input type="text" name="phone_number" id="phone_number" value={userData.phone_number} onChange={event => handleChange("phone_number", event.target.value)} className="input-text" required />
          </div>
          <div className= "form-row">
            <label htmlFor="address">Alamat Lengkap <span className="text-danger">*</span></label>
            <input type="text" name="address" id="address" className="input-text" value={userRegion.address} onChange={event => userRegion.setAddress(event.target.value)} required />
          </div>
          <div className= "form-row">
            <label htmlFor="addressRT">RT (3 Digit) <span className="text-danger">*</span></label>
            <input type="text" name="addressRT" id="addressRT" className="input-text" value={userRegion.rt} onChange={event => userRegion.setRt(event.target.value)} required />
          </div>
          <div className= "form-row">
            <label htmlFor="addressRW">RW (3 Digit) <span className="text-danger">*</span></label>
            <input type="text" name="addressRW" id="addressRW" className="input-text" value={userRegion.rw} onChange={event => userRegion.setRw(event.target.value)} required />
          </div>
          <div className= "form-row">
            <label htmlFor="user_province">Provinsi <span className="text-danger">*</span></label>
            <select className="input form-control" id="user_province" required value={userRegion.province} onChange={event => userRegion.setProvince(event.target.value)}>
              <option value="">-- Pilih Provinsi --</option>
              {userRegion.provinces.map((province, index) => (
                <option key={index} value={province.province}>{province.province}</option>
              ))}
            </select>
          </div>
          <div className= "form-row">
            <label htmlFor="user_city">Kota/Kabupaten <span className="text-danger">*</span></label>
            <select className="input form-control" id="user_city" required value={userRegion.city} onChange={event => userRegion.setCity(event.target.value)}>
              <option value="">-- Pilih Kota / Kab --</option>
              {userRegion.cities.map((city, index) => (
                <option key={index} value={city.kota_kab}>{city.kota_kab}</option>
              ))}
            </select>
          </div>
          <div className= "form-row">
            <label htmlFor="your_email">Kecamatan <span className="text-danger">*</span></label>
            <select className="input form-control" id="user_district" required value={userRegion.district} onChange={event => userRegion.setDistrict(event.target.value)}>
              <option value="">-- Pilih Kecamatan --</option>
              {userRegion.districts.map((district, index) => (
                <option key={index} value={district.kecamatan}>{district.kecamatan}</option>
              ))}
            </select>
          </div>
          <div className= "form-row">
            <label htmlFor="user_kelurahan">Kelurahan <span className="text-danger">*</span></label>
            <select className="input form-control" id="user_kelurahan" required value={userRegion.addressId} onChange={event => userRegion.setAddressId(event.target.value)}>
              <option value="">-- Pilih Kelurahan --</option>
              {userRegion.addresslist.map((kelurahan, index) => (
                <option key={index} value={kelurahan.id}>{kelurahan.kelurahan} ({kelurahan.postal_code})</option>
              ))}
            </select>
          </div>


          <h2>Data Pemasangan</h2>
          <div className="form-checkbox">
            <label className="container"><p className="ml-2">Data alamat pemasangan sama dengan alamat pelanggan </p>
              <input type="checkbox" name="checkbox" checked={sameAddress} onChange={() => setSameAddress(!sameAddress)} />
              <span className="checkmark"></span>
            </label>
          </div>

          <div className= "form-row">
            <label htmlFor="building_ownership_status">Status Lokasi Pemasangan <span className="text-danger">*</span></label>
            <select className="input form-control" id="building_ownership_status" value={userData.building_ownership_status} onChange={event => handleChange("building_ownership_status", event.target.value)}>
              <option value="">-- Status --</option>
              <option value="OWN">Milik Sendiri</option>
              <option value="RENT">Sewa</option>
              <option value="BOARDING">Kost</option>
            </select>
          </div>

          {/* Collapsible install address */}
          <Collapse in={!sameAddress}>
          <div>
            <div className= "form-row">
              <label htmlFor="install_address">Alamat Lengkap <span className="text-danger">*</span></label>
              <input type="text" name="install_address" id="install_address" className="input-text" required={!sameAddress} value={installRegion.address} onChange={event => installRegion.setAddress(event.target.value)} />
            </div>
            <div className= "form-row">
              <label htmlFor="install_addressRT">RT (3 Digit) <span className="text-danger">*</span></label>
              <input type="text" name="install_addressRT" id="install_addressRT" required={!sameAddress} className="input-text" value={installRegion.rt} onChange={event => installRegion.setRt(event.target.value)} />
            </div>
            <div className= "form-row">
              <label htmlFor="install_addressRW">RW (3 Digit) <span className="text-danger">*</span></label>
              <input type="text" name="install_addressRW" id="install_addressRW" required={!sameAddress} className="input-text" value={installRegion.rw} onChange={event => installRegion.setRw(event.target.value)} />
            </div>
            <div className= "form-row">
              <label htmlFor="install_province">Provinsi <span className="text-danger">*</span></label>
              <select className="input form-control" id="install_province" required={!sameAddress} value={installRegion.province} onChange={event => installRegion.setProvince(event.target.value)}>
                <option value="">-- Pilih Provinsi --</option>
                {installRegion.provinces.map((province, index) => (
                  <option key={index} value={province.province}>{province.province}</option>
                ))}
              </select>
            </div>
            <div className= "form-row">
              <label htmlFor="install_city">Kota/Kabupaten <span className="text-danger">*</span></label>
              <select className="input form-control" id="install_city" required={!sameAddress} value={installRegion.city} onChange={event => installRegion.setCity(event.target.value)}>
                <option value="">-- Pilih Kota / Kab --</option>
                {installRegion.cities.map((city, index) => (
                  <option key={index} value={city.kota_kab}>{city.kota_kab}</option>
                ))}
              </select>
            </div>
            <div className= "form-row">
              <label htmlFor="install_district">Kecamatan <span className="text-danger">*</span></label>
              <select className="input form-control" id="install_district" required={!sameAddress} value={installRegion.district} onChange={event => installRegion.setDistrict(event.target.value)}>
                <option value="">-- Pilih Kecamatan --</option>
                {installRegion.districts.map((district, index) => (
                  <option key={index} value={district.kecamatan}>{district.kecamatan}</option>
                ))}
              </select>
            </div>
            <div className= "form-row">
              <label htmlFor="install_kelurahan">Kelurahan <span className="text-danger">*</span></label>
              <select className="input form-control" id="install_kelurahan" required={!sameAddress} value={installRegion.addressId} onChange={event => installRegion.setAddressId(event.target.value)}>
                <option value="">-- Pilih Kelurahan --</option>
                {installRegion.addresslist.map((kelurahan, index) => (
                  <option key={index} value={kelurahan.id}>{kelurahan.kelurahan} ({kelurahan.postal_code})</option>
                ))}
              </select>
            </div>
            </div>
          </Collapse>

          <div className="form-checkbox mt-3 mb-3">
            <label className="container"><p className='ml-2'>Saya setuju dengan <a href="#tnc" className="text">Syarat dan Ketentuan</a> yang berlaku <span className="text-danger">(Wajib diisi)</span></p>
              <input type="checkbox" name="checkbox" checked={agreeTnc} onChange={() => setAgreeTnc(!agreeTnc)} required />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="form-row-last">
            <input type="submit" name="register" className="register" value={loading ? 'Menunggu ...' : 'Simpan'}  disabled={loading}  />
          </div>
        </form>
      </div>
    </div>
  )
}

export default RegisterPage;